<template>
  <div>
    <div v-if="showTab">
      <el-tabs v-model="routePath" @tab-click="handleClick" tab-position="top">
        <el-tab-pane v-for="tab in tabList" :key="tab.label" :label="tab.label" :name="tab.address"> </el-tab-pane>
      </el-tabs>

      <iframe :src="iframeUrl" scrolling="yes" width="100%" height="100%" frameborder="0"></iframe>
    </div>
    <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); border:none" v-else>
      <img src="../../assets/images/embedded.png" alt="" style="border:none" />
    </div>
  </div>
</template>

<script>
import { getTkbApiCallTicket, getCallTkbLogin } from "@/api/aIMarketing";
let base64 = require("js-base64").Base64;
export default {
  name: "",
  data() {
    return {
      tabList: [
        {
          label: "企业搜索",
          address: "/searvistor/find",
        },
        {
          label: "高级搜索",
          address: "/searvistor/supersearch",
        },
        {
          label: "领取列表",
          address: "/searvistor/receivelist",
        },
        {
          label: "地图获客",
          address: "/searvistor/map",
        },
        {
          label: "批量查询",
          address: "/searvistor/batch",
        },
        {
          label: "招标查询",
          address: "/searvistor/tender",
        },
        {
          label: "店铺查询",
          address: "/searvistor/shopquery",
        },
        {
          label: "展会查询",
          address: "/searvistor/expo",
        },
        {
          label: "客情监控",
          address: "/searvistor/companyMonitor",
        },
        {
          label: "客群",
          address: "/searvistor/favorite",
        },
      ],
      // tab默认标签
      routePath: "/searvistor/find",
      // 时间戳
      timeStamp: "",
      appkey: 23417643,
      paym: {
        customer: 6835,
        act: "tkb_lis",
        appid: 3033,
        remote_fans_id: 3030,
        sign: "26b74c255ffd9662723156934a47f3d7",
      },
      TicketData: "",
      iframeUrl: "",
      loginData: "",
      showTab: false,
    };
  },
  created() {
    this.getWebApi();
  },
  methods: {
    // 点击tab栏
    handleClick(tab, event) {
      console.log(tab.name);
      this.routePath = tab.name;
      this.CallTkbLogin();
    },
    getWebApi() {
      getTkbApiCallTicket()
        .then(res => {
          if (res.code == 200) {
            this.showTab = true;
            this.TicketData = res.data[0];
            // console.log("获取探客宝坐席账号", this.TicketData);
            this.CallTkbLogin();
          } else if (res.code == 201) {
            if (res.message.includes("智云声")) {
              this.$message.error("企业未授权无法操作!");
              return;
            }
            this.$message(res.message);
            return;
          }
        })
        .catch(err => {
          this.$message(err.message);
        });
    },
    async CallTkbLogin() {
      let data = base64.encode(JSON.stringify({ uid: this.TicketData.id }));
      let res = await getCallTkbLogin({ data });
      if (res.code == 200) {
        // console.log("登录探客宝", res.data.data);
        this.loginData = res.data.data;
        this.iframeUrl = `https://tkb.51sanu.com/xk/auth/broadCast?source=agency&bcwid=${this.loginData.bcWid}&pid=${this.loginData.pid}&userWid=${this.loginData.userWid}&accountId=${this.loginData.accountId}&token=${this.loginData.token}&routePath=${this.routePath}`;
      } else {
        this.$message(res.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
iframe {
  min-height: 1000px !important;
  overflow: visible;
  height: 100%;
}
/* 隐藏默认的滚动条 */
::-webkit-scrollbar {
  width: none !important;
}

/* 定义滚动条的轨道样式 */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* 定义滚动条的滑块样式 */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

/* 鼠标悬停在滚动条上时的样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.wrapper {
  .content-box {
    .content {
      ::-webkit-scrollbar {
        width: none !important;
      }
    }
  }
}
</style>
